<!--
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 16:08:46
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-06 12:51:17
 * @FilePath: /hblg-site-dev/src/views/app/yuanqu.vue
-->
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .list {
    margin-bottom: 20px;
    display: inline-block;
    .ant-card {
      float: left;
      margin-right: 10px;
    }
  }
  .building {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
</style>

<template>
  <div class="container">
    <template v-if="data && data.length > 0">
      <div v-for="(item, key) in data" :key="key">
        <div class="title">{{ item.title }}</div>
        <div class="list">
          <a-card hoverable style="width: 300px" v-for="(item2, key) in item.children" :key="key">
            <img slot="cover" alt="example" :src="`/images/xiaofang.png`" />
            <template slot="actions" class="ant-card-actions">
              <a-tooltip v-if="item2.link.datav" title="可视化大屏">
                <a-icon key="fund" type="fund" @click="open(item2.link.datav)" />
              </a-tooltip>
              <a-tooltip v-if="item2.link.web" title="访问后台">
                <a-icon key="global" type="global" @click="open(item2.link.web)" />
              </a-tooltip>
              <a-tooltip v-if="item2.link.app.android" title="安卓APP">
                <a-icon key="android" type="android" @click="open(item2.link.app.android)" />
              </a-tooltip>
              <a-tooltip v-if="item2.link.app.ios" title="苹果APP">
                <a-icon key="apple" type="apple" @click="open(item2.link.app.ios)" />
              </a-tooltip>
            </template>
            <a-card-meta :title="item2.title" :description="item2.description" />
          </a-card>
        </div>
      </div>
    </template>
    <div v-else class="building">建设中...</div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      data: null,
    };
  },
  async mounted() {
    const { data } = await axios.get('/json/yuanqu.json');
    this.data = data;
  },
};
</script>
